module.exports = [{
      plugin: require('F:/Developer/www/gatsby-london-after-midnight/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":""},
    },{
      plugin: require('F:/Developer/www/gatsby-london-after-midnight/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"arthew0","short_name":"LAM","start_url":"/","background_color":"#ffffff","theme_color":"#663399","display":"minimal-ui","icon":"content/assets/gatsby-icon.png"},
    },{
      plugin: require('F:/Developer/www/gatsby-london-after-midnight/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('F:/Developer/www/gatsby-london-after-midnight/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
